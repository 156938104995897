import React from "react";
import SEO from "../components/seo/seo";
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import { injectIntl, FormattedMessage } from "gatsby-plugin-react-intl";
import { graphql } from "gatsby";
import SideVerticalText from "../components/layout/sideVerticalText";
import BottomFactory from "../components/layout/BottomFactory";
import FadeIn from "../components/animations/FadeIn";

export const query = graphql`
	query($slug: String!) {
		allProcessLinesJson(slug: { eq: $slug }) {
			title
			subTitle
			characteristics
			need
			imageMain {
				childImageSharp {
					original {
						src
					}
				}
			}
			image02 {
				childImageSharp {
					original {
						src
					}
				}
			}
			image03 {
				childImageSharp {
					original {
						src
					}
				}
			}
		}
	}
`;

const AllProcessLine = ({ data, intl }) => {
	const line = data.allProcessLinesJson;
	const graphImage1 = line.imageMain.childImageSharp.original.src;
	const graphImage2 = line.image02.childImageSharp.original.src;
	const graphImage3 = line.image03.childImageSharp.original.src;
	const images = [
		{
			original: graphImage1,
			thumbnail: graphImage1,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage2,
			thumbnail: graphImage2,
			originalAlt: 'machine Image'
		},
		{
			original: graphImage3,
			thumbnail: graphImage3,
			originalAlt: 'machine Image'
		},
	];

	return (
		<>
			<SEO
				title={intl.formatMessage({ id: "machineSelection.processLines" })}
			/>

			<div className="gridAllMachinesCreatePagesHome">
				<div className="boxAllMachinesCreatePagesHomeSideText">
					<FadeIn duration="2s" delay="0.5s"><SideVerticalText /></FadeIn>
				</div>

				<div className="boxAllMachinesCreatePagesHomeTopText">
					<div className="allMachinesSecondLevelTitle">
						<p>
							<FormattedMessage id={line.title} />
						</p>
					</div>
					<div className="allMachinesSecondLevelSubTitle">
						<div className="allAllMachinesProcessCircle"></div>
						<p className="allAllMachinesCircleText">
							<FormattedMessage id={line.subTitle} />
						</p>
					</div>
				</div>

				<div className="boxAllMachinesCreatePagesHomeLeft">
					<ImageGallery
						items={images}
						lazyLoad={false}
						showPlayButton={false}
						showFullscreenButton={false}
						useBrowserFullscreen={false}
						showThumbnails={false}
						showNav={true}
						showBullets={false}
						autoPlay={false}
						slideDuration={1000}
						/*slideInterval={10000} */
					/>
				</div>
				<div className="boxAllMachinesCreatePagesHomeRight">
					<div className="allAllMachinesTableWrapper">
						<p className="allAllMachinesCharacteristics">
						<FormattedMessage id={line.characteristics} />
						</p>
						<div className="processCreatePageSectionTable">
						<table>
							<tbody>
								{line.need.map((item, index) => {
									return (
										<tr key={index} className="processCreatePageTable">
											<td className="processCreatePageTableText">
												<FormattedMessage id={item} />
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
					</div>
				</div>
				{/* <div className="boxAllMachinesCreatePagesHomeRight">
					<div className="processCreatePageSectionTable">
						<table>
							<tbody>
								{line.need.map((item, index) => {
									return (
										<tr key={index} className="processCreatePageTable">
											<td className="processCreatePageTableText">
												<FormattedMessage id={item} />
											</td>
										</tr>
									);
								})}
							</tbody>
						</table>
					</div>
				</div> */}
				<div className="boxAllMachinesCreatePagesHomeEmptyRight"></div>
				<div className="boxAllMachinesCreatePagesHomeBottomText">
					<BottomFactory />
				</div>
			</div>
		</>
	);
};

export default injectIntl(AllProcessLine);
